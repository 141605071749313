import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';

const mock = [
  {
    media: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img8.png',
    title: 'Financial support',
    subtitle:
      'In the event of a serious illness, trauma cover provides a lump sum payment that can help cover the cost of treatment and recovery, as well as provide financial stability during a time of uncertainty.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/Delayedcare.png',
    title: 'Access to quality care',
    subtitle:
      'With the financial support provided by trauma cover, you can access quality medical care and treatment that you may not have been able to afford otherwise.',
  },
  {
    media: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/img1.jpg',
    title: 'Flexibility',
    subtitle:
      'You can use the payout to cover any expenses you choose, whether it be medical bills or day-to-day living expenses.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/Reducedaccesstohealthcare.png',
    title: 'Early intervention',
    subtitle:
      'In some cases, a lump sum payment can allow you to seek early intervention and treatment that may not otherwise be possible.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
    title: 'Choice of treatment options',
    subtitle:
      'Having trauma cover allows you to make your own choices about the medical treatment, rather than being limited by your ability to pay for it.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
    title: 'Maintaining standard of living',
    subtitle:
      'The lump sum payment from trauma cover can help you maintain your standard of living and meet your financial obligations while you are unable to work.',
  },
  {
    media:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
    title: 'Peace of mind',
    subtitle:
      'Knowing that you are financially protected in the event of a serious illness can provide peace of mind for you and your family.',
  },
  // {
  //   media:
  //     'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
  //   title: 'Cover for pre-existing conditions',
  //   subtitle:
  //     'Some trauma cover policies may offer coverage for pre-existing conditions, providing added security for you.',
  // },
  // {
  //   media:
  //     'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/preventivecare2.jpg',
  //   title: 'No impact on other insurance',
  //   subtitle:
  //     'Having trauma cover does not affect other insurance policies, such as life insurance or total and permanent disability insurance.',
  // },
];

const News = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant={'h6'}
          component={'p'}
          color={'text.secondary'}
          align={'center'}
        >
          Here are some reasons for having Trauma Insurance:
        </Typography>
      </Box>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            {mock.map((item, index) => (
              <Grid
                item
                xs={12}
                key={index}
                data-aos="fade-up"
                data-aos-delay={index * 200}
                data-aos-offset={100}
                data-aos-duration={600}
              >
                <Box
                  component={Card}
                  display={'flex'}
                  flexDirection={{ xs: 'column', sm: 'row' }}
                >
                  <CardMedia
                    title={item.title}
                    image={item.media}
                    sx={{
                      height: { xs: 240, sm: 'auto' },
                      width: { xs: 1, sm: 300 },
                    }}
                  />
                  <CardContent>
                    <Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        color="text.primary"
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card} bgcolor={'primary.main'}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                    You like what you’re reading?
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ color: 'common.white' }}
                  >
                    Get online insurance advice.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent>
                  <Typography variant="h6" gutterBottom color="text.primary">
                    Interactive decision support system
                  </Typography>
                  <Link
                    to={'/contact'}
                    style={{ textDecoration: 'none' }} // Remove default link styling
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      endIcon={
                        <Box
                          component={'svg'}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width={24}
                          height={24}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </Box>
                      }
                    >
                      Contact us
                    </Button>
                  </Link>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default News;
