/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/trauma1.jpg',
    description:
      "The payment from a trauma insurance policy can be used to help cover medical expenses, pay for rehabilitation, or provide financial support in the event that you are unable to work due to your illness. It is designed to help alleviate financial stress and provide peace of mind in the event of a critical illness.",
    desc:"Trauma insurance is typically sold as a standalone policy or as an add-on to a life insurance policy. It's important to carefully review the terms and conditions of the policy to understand what is covered, and what is not covered. It's also recommended to consult with a financial adviser to understand the coverage options and ensure the policy fits with your overall financial plan.",
    title: 'Trauma Insurance',
    tags: [],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image: 'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/trauma.jpg',
    description:
      "By having trauma cover, you can reduce the financial stress and burden that often accompanies a serious illness, allowing you to focus on recovery and return to good health.",
      desc:"",
      title: 'Why do you need Trauma Insurance?',
      tags: [],
      author: {
        name: 'Jhon Anderson',
        avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
      },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          data-aos={'fade-up'}
          align={'center'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Trauma Insurance
        </Typography>
        <Typography
          variant="h6"
          color={'text.secondary'}
          align={'center'}
          data-aos={'fade-up'}
        >
          Trauma insurance, also known as critical illness insurance, is a type of insurance policy that provides a lump sum payment in the event that you are diagnosed with a specified critical illness or medical condition. Trauma insurance covers a range of illnesses, including cancer, heart attack, stroke, major organ failure or major organ transplant.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              borderRadius={0}
              boxShadow={0}
              display={'flex'}
              flexDirection={{
                xs: 'column',
                md: i % 2 === 0 ? 'row-reverse' : 'row',
              }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50%' },
                }}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    objectFit: 'cover',
                    maxHeight: 360,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  paddingX: { xs: 1, sm: 2, md: 4 },
                  paddingY: { xs: 2, sm: 4 },
                  width: { xs: 1, md: '50%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  {item.tags.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      component="a"
                      href=""
                      clickable
                      size={'small'}
                      color={'primary'}
                      sx={{ marginBottom: 1, marginRight: 1 }}
                    />
                  ))}
                </Box>
                <Typography
                  variant={'h6'}
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {item.title}
                </Typography>
                <Typography color="text.secondary">
                  {item.description}
                  <br/>
                  <br/>
                  {item.desc}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularNews;
